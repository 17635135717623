import React, { useState, useEffect, useContext } from "react";
import { LanguageContext } from "../../../context/LanguageContext";
import axios from "axios";
import { BaseURL } from "../../../Helper/config";
import { toast } from "react-toastify";
import { Tab, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useGetBankListQuery } from "../../../Redux/API/ApiSlice";
import getTranslation from "../../../Helper/getTranslationUtility";
import labels from "../../../translationData/editProfile.json";
const mobileBankingTab = labels.userDashboard.editProfile.mobileBankingTab;

const MobileBanking = () => {
  const { handleSubmit, setError, clearErrors, formState: { errors }, } = useForm();

  const { currentLanguage, setCurrentLanguage } = useContext(LanguageContext);
  const successMessage = getTranslation(
    "successMessage",
    currentLanguage,
    mobileBankingTab
  );
  const errorMessage = getTranslation(
    "errorMessage",
    currentLanguage,
    mobileBankingTab
  );

  const { data: providers, isSuccess } = useGetBankListQuery("mobileBanking");
  const Seller = JSON.parse(localStorage.getItem("UserDetails"));
  const [mobileBankingCollection, setMobileBanking] = useState([]);
  const handleProviderNameChange = (event, index) => {
    const value = event.target.value;
    setMobileBanking((prev) => {
      prev[index].operatorName = value;
      return [...prev];
    });
  };
  const handleMobileNumberChange = (event, index) => {
    const value = event.target.value;
    setMobileBanking((prev) => {
      prev[index].mobileNumber = value;
      return [...prev];
    });
  };
  const addMobileBanking = () => {
    setMobileBanking((prev) => {
      return [
        ...(prev || []),
        {
          operatorName: "",
          mobileNumber: "",
        },
      ];
    });
  };
  const removeMobileBanking = (i) => {
    setMobileBanking(
      mobileBankingCollection.filter((item, index) => index != i)
    );
  };

  const validateMobileNumber = (mobileNumber, index) => {
    if (!mobileNumber) {
      setError(`mobileBanking[${index}].mobileNumber`, {
        type: "manual",
        message: "Mobile number cannot be empty.",
      });
      return false;
    }

    const mobileNumberRegex = /^(013|016|017|018|019|015)[0-9]{7}$/;
    if (!mobileNumberRegex.test(mobileNumber)) {
      setError(`mobileBanking[${index}].mobileNumber`, {
        type: "manual",
        message: "Invalid mobile number. It must start with 013, 016, 017, 018, or 019 and be 11 digits long.",
      });
      return false;
    }

    clearErrors(`mobileBanking[${index}].mobileNumber`);
    return true;
  };

  const handleBecomeSeller = async () => {
    const token = localStorage.getItem("Token");
    const headers = token ? { token: `${token}` } : {};

    let fdata = {
      user: {},
      seller: {
        mobileBanking: [...mobileBankingCollection],
      },
    };

    axios
      .post(`${BaseURL}/update-user-details/${Seller._id}`, fdata, { headers })
      .then((res) => {
        if (res.data.status === "Success") {
          toast.success(successMessage, {
            position: "bottom-right",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        } else {
          toast.warning(errorMessage);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    const token = localStorage.getItem("Token");
    const headers = token ? { token: `${token}` } : {};

    axios
      .get(`${BaseURL}/get-user-details/${Seller._id}`, { headers })
      .then((res) => {
        const data = res.data.data[0].sellerData;
        if (data && data.mobileBanking.length > 0) {
          setMobileBanking([...data.mobileBanking]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <Tab.Pane eventKey="11">
      <form onSubmit={handleSubmit(handleBecomeSeller)}>
        <div className="setting-right">
          <div className="card">
            <div className="card-body">
              <div className="card-header border-0 pb-0">
                <h3>
                  {getTranslation(
                    "tabDetailsTitle",
                    currentLanguage,
                    mobileBankingTab
                  )}
                </h3>
              </div>
              <div className="card-body">
                {mobileBankingCollection &&
                  mobileBankingCollection.length > 0 && (
                    <div className="row">
                      <div className="col-5">
                        <label>
                          {getTranslation(
                            "selectProvider",
                            currentLanguage,
                            mobileBankingTab
                          )}
                        </label>
                      </div>
                      <div className="col-5">
                        <label>
                          {getTranslation(
                            "selectMobileNo",
                            currentLanguage,
                            mobileBankingTab
                          )}
                        </label>
                      </div>
                    </div>
                  )}
                {mobileBankingCollection &&
                  mobileBankingCollection.map((mobileBanking, index) => {
                    return (
                      <div key={index} className="row align-items-center mb-3">
                        <div className="form-group col-5">
                          <select
                            className="form-control"
                            onChange={(e) => handleProviderNameChange(e, index)}
                          >
                            <option value="">
                              {getTranslation(
                                "selectProviderPlaceholder",
                                currentLanguage,
                                mobileBankingTab
                              )}
                            </option>
                            {providers &&
                              providers.data.map((item, index) => {
                                return (
                                  <option
                                    selected={mobileBanking.operatorName === item.bankName}
                                    className={item.bankName}
                                  >
                                    {item.bankName}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                        <div className="form-group col-5">
                          <input
                            type="text"
                            className="form-control"
                            placeholder={getTranslation(
                              "selectMobileNo",
                              currentLanguage,
                              mobileBankingTab
                            )}
                            value={mobileBanking.mobileNumber || ""}
                            onChange={(e) => handleMobileNumberChange(e, index)}
                            onBlur={(e) => validateMobileNumber(e.target.value, index)}
                          />
                          {errors?.mobileBanking?.[index]?.mobileNumber && (
                            <div className="error-message text-danger">
                              {errors.mobileBanking[index].mobileNumber.message}
                            </div>
                          )}
                        </div>
                        <div className="col-2">
                          <Button
                            className="w-100"
                            variant="danger"
                            onClick={() => removeMobileBanking(index)}
                          >
                            <i className="fas fa-trash-alt"></i>
                          </Button>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
          <div className="card-footer pt-3 pb-2">
            <Button
              className="w-100"
              variant="secondary"
              onClick={addMobileBanking}
            >
              + Add More Mobile Number
            </Button>
            <Button
              className="w-100 mt-2"
              variant="primary"
              type="submit"
            >
              Save Changes
            </Button>
          </div>
        </div>
      </form>
    </Tab.Pane>
  );
};

export default MobileBanking;
