import React, { useState, useEffect } from "react";
import { Link, redirect, useLocation, useNavigate } from "react-router-dom";

import axios from "axios";
import { ImCross } from "react-icons/im";
import { BaseURL } from "../../../Helper/config";
import {
  useGetBankListQuery,
  useGetSellerInfoQuery,
} from "../../../Redux/API/ApiSlice";
import { Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { getUserDetails } from "../../../Helper/SessionHelper";
import { getSeller } from "../../../Redux/feature/Authentication/AuthenticationSlice";
// import ImageUploader from "../../../ImageUploader/ImageUploader";
import { toast } from "react-toastify";
import { ImageUploader } from "../../../ImageUploader/ImageUploader";
import Swal from "sweetalert2";
// import { BaseURL } from "../../../../../Helper/config";
import bgimage from "../../../images/no-img-avatar.png";
import labels from "../../../translationData/userDashboardLabels.json";
import UploaderModel from "../../Common/UploaderModel/UploaderModel";

function UpdateBankInfo() {
  const {
    register,
    formState: { errors },
    handleSubmit,
    setError,
  } = useForm();

  const {
    data: BankList,
    isLoading: BankDataLoading,
    isSuccess: BankDataSuccess,
  } = useGetBankListQuery("Bank");
  const {
    data: MblBankList,
    isLoading: MblBankDataLoading,
    isSuccess: MblBankDataSuccess,
  } = useGetBankListQuery("mobileBanking");
  const userData = getUserDetails();
  const [name, setName] = useState(userData.userFullName);
  const navigate = useNavigate();
  const { data } = useGetSellerInfoQuery(userData._id);
  const [sellerImageFile, setSellerImageFile] = useState();
  const [mobileBank, setMobileBank] = useState([]);
  const [number, setNumber] = useState("");
  const [mobileBankName, setMobileBankName] = useState("");
  const [selectedID, setSelectedID] = useState("NID");
  const [identityImgFile, setIdentityImgFile] = useState([]);
  const [nidBackFile, setNidBackFile] = useState([]);
  const [nidData, setNidData] = useState([]);

  const user = JSON.parse(localStorage.getItem("UserDetails"));
  const updateUser = async () => {
    // Get the token from localStorage
    const token = localStorage.getItem("Token");

    // Set the token in headers if it exists
    const headers = token ? { token: `${token}` } : {};

    // Send the request with the headers
    axios
      .post(`${BaseURL}/update-users/${user._id}`, { sellerStatus: "PENDING" }, { headers })
      .then((res) => {
        if (res.data.status === "Success") {
          console.log(res.data.data);
          //  setCountry(res.data.data);
        }
      });
  };
  const [isButtonDisabled, setIsButtonDisabled] = useState(true); // Track button state


  const [errorMessage, setErrorMessage] = useState("");
  // const [number, setNumbere] = useState("");

  const handleValidation = (value) => {
    const validPrefixes = ["013", "014", "015", "016", "017", "018", "019"];


    const formattedValue = value.startsWith("880") ? value.slice(3) : value;


    if (formattedValue.length !== 11) {
      setErrorMessage("Mobile number must be exactly 11 digits long");
      setIsButtonDisabled(true);
      return false;
    }

    const prefix = formattedValue.slice(0, 3);
    if (!validPrefixes.includes(prefix)) {
      setErrorMessage(`Mobile number must start with a valid Bangladeshi prefix: ${validPrefixes.join(", ")}`);
      setIsButtonDisabled(true);
      return false;
    }


    setIsButtonDisabled(false);
    setErrorMessage("");
    return true;
  };

  const handleIdentity = (e) => {
    const selectedValue = e.target.value;
    setSelectedID(selectedValue);
  };

  const handleNID = (e) => {
    const file = Array.from(e.target.files);
    setIdentityImgFile([...identityImgFile, ...file]);
  };

  //delete image from local
  const handleImageDelete = (index) => {
    const updatedImages = [...identityImgFile];
    updatedImages.splice(index, 1);
    setIdentityImgFile(updatedImages);
  };
  // --------------------deprecated------------------
  //                                                  |
  // const handleNIDBack = (e) => {                   |
  //   const file = e.target.files[0];                |
  //   // console.log('hello',file)                   |
  //   setNidBackFile(file);                          |
  // };                                               |
  //-------------------------------------------------
  const handleImage = (e) => {
    const fileList = e.target.files[0];
    console.log(fileList);
    setSellerImageFile(fileList);
  };

  // const params = useQueryParams();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const id = query.get("id");

  const handleBankDetails = async (value) => {


    // Get the token from localStorage
    const token = localStorage.getItem("Token");

    // Set the token in headers if it exists
    const headers = token ? { token: `${token}` } : {};

    // Upload seller profile photo
    const sellerPhoto = await ImageUploader(userData, "sellerProfile", sellerImageFile);
    let nid = [];

    // Handle the identity images
    const NidFront = await ImageUploader(userData, "Identity", identityImgFile);
    console.log("Before setNidData1", NidFront, identityImgFile);
    if (NidFront.status === "Success") {
      nid.push(NidFront.images);
    }

    const dateOfBirth = new Date(value.DateOfBirth);
    const today = new Date();
    let age = today.getFullYear() - dateOfBirth.getFullYear();
    if (today.getMonth() < dateOfBirth.getMonth() ||
      (today.getMonth() === dateOfBirth.getMonth() && today.getDate() < dateOfBirth.getDate())) {
      age--;
    }

    if (age < 18) {
      setError("DateOfBirth", { type: "manual", message: "You must be at least 18 years old." });
    return ;
    
    } 
    console.log(!mobileBank.length,(!value.accountNumber && !value.bankName))
   if (!mobileBank.length && (!value.accountNumber && !value.bankName)) {
      toast.error("Please Add at List one Bank Information!!")
        
      setError("Please Add at List one Bank Information!!")
    } else {
      // Prepare data for the API request
      const data = {
        user: {
          userFullName: name,
          Identity: [{ type: selectedID, Number: value.IDnumber, Img: nid[0] }],
          userDateOfBirth: value.DateOfBirth,
          userProfilePhoto: sellerPhoto.images,
        },
      
        bankDetails: [{
          bankAccNo: value.accountNumber,
          bankAccName: value.accountName,
          bankBranchName: value.bankBranchName,
          bankRoutingNo: value.routingNumber,
          bankName: value.bankName,
        }],
        mobileBanking: mobileBank,
        sellerApproval: "Pending",
      };
  // sellerProfilePhoto: sellerPhoto.images,
      // Make the request with headers
      axios
        .post(`${BaseURL}/update-seller-user/${id}`, data, { headers })
        .then((res) => {
          console.log(res.data.status, "form res");
          updateUser();
          if (res.data.status === "Success") {
            console.log(res);
            Swal.fire({
              icon: "success",
              title: `আপনাকে পেয়ে আমরা আনন্দিত। বিক্রেতা হিসেবে আপনার আবেদন গ্রহন করা হয়েছে।`,
              showConfirmButton: true,
            }).then((result) => {
              if (result.isConfirmed) {
                navigate("/");
              }
            });
          } else {
            toast("Something Went Wrong!!!");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };


  const handleMonileBank = () => {
    setMobileBank([
      ...mobileBank,
      { operatorName: mobileBankName, mobileNumber: number },
    ]);
    setMobileBankName("")
    setNumber("")
  };
  const handleDelete = (index) => {
    const updatedMobileBank = mobileBank.filter((_, i) => i !== index);
    setMobileBank(updatedMobileBank);
  };

  const inputLabels = labels.userDashboard.becomeSellerModule;
  const loader = useSelector((state) => state.ImageLoader.loading);
  return (
    <>
      <UploaderModel loader={loader} />
      <div className="row">
        <div className="col-xl-8">
          <div className="card">
            <div class="card-header">
              <h4 class="card-title">
                নিজেই বিক্রেতা হয়ে উঠুন - দ্বিতীয় ধাপ
              </h4>
            </div>

            <div className="card-body">
              <div className="withdrow-bx basic-form">
                <form onSubmit={handleSubmit(handleBankDetails)}>
                  <div className="row mb-4">
                    <div className="col-5">
                      <label className="form-label">
                        {inputLabels.profilePhoto.bn}
                        <span className="text-danger">*</span>
                      </label>
                      {/* <div>
                      {sellerImageFile && (
                        <img
                          style={{ height: "100px", width: "100px" }}
                          className='img-fluid rounded-circle'
                          alt='not found'
                          width={"80px"}
                          src={URL.createObjectURL(sellerImageFile)}
                        />
                      )}

                      <br />
                    </div> */}
                      <div>
                        <img
                          style={{ height: "100px", width: "100px" }}
                          className="img-fluid rounded-circle"
                          src={
                            sellerImageFile
                              ? URL.createObjectURL(sellerImageFile)
                              : (userData &&
                                userData.userProfilePhoto &&
                                userData.userProfilePhoto[0] &&
                                userData.userProfilePhoto[0]
                                  .orginalImageUrl) ||
                              bgimage
                          }
                          alt={sellerImageFile ? sellerImageFile.name : null}
                        />
                      </div>
                    </div>
                    <div className="col-5">
                      <input
                        name="readyFoodImage"
                        type="file"
                        id="input-file-upload"
                        onChange={handleImage}
                      // onChange={handleKitchenPhoto}
                      />
                      <label id="label-file-upload" htmlFor="input-file-upload">
                        <div>
                          <p>আপনার ছবি এখানে টেনে আনুন বা ড্রপ করুন </p>
                          <span className="upload-button">
                            আপনার ছবি আপলোড করুন
                          </span>
                        </div>
                      </label>
                    </div>
                  </div>
                  <div className="mb-3">
                    <label className="form-label">{inputLabels.name.bn}</label>
                    <input
                      type="text"
                      onChange={(e) => setName(e.target.value)}
                      className="form-control"
                      defaultValue={userData.userFullName}
                      name="name"

                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">
                      {inputLabels.identity.bn}
                    </label>
                    <select
                      name="identity"
                      className="form-control input-default "
                      onChange={handleIdentity}

                    >
                      <option value="NID">{inputLabels.identity.nid.bn}</option>
                      <option value="passport">
                        {inputLabels.identity.passport.bn}
                      </option>
                      <option value="BID">
                        {inputLabels.identity.birthID.bn}
                      </option>
                    </select>
                  </div>
                  <div className="mb-3">
                    <label className="form-label">
                      {/* hlw */}
                      {inputLabels.identity.cardnumber.bankAccName}
                    </label>
                    {selectedID === "NID" && (
                      <div>
                        <input
                          type="text"
                          {...register("IDnumber", { required: true })}
                          className="form-control"
                          placeholder="Enter Your NID Number"
                          name="IDnumber"
                        // value={bank_contact.number}
                        // onChange={handleChange}
                        />
                        <div className="row">
                          <div className="col-12">
                            {identityImgFile &&
                              identityImgFile.map((i, index) => (
                                <div
                                  className="col-6 mb-3 d-flex"
                                  style={{
                                    height: "100px",
                                    overflow: "hidden",
                                    width: "100px",
                                    position: "relative",
                                  }}
                                >
                                  <img
                                    className="img-fluid"
                                    src={URL.createObjectURL(i)}
                                    alt=""
                                  />

                                  <div className="position-absolute top-0 end-0">
                                    <ImCross
                                      onClick={() => handleImageDelete(index)}
                                      color="red"
                                    />
                                  </div>
                                </div>
                              ))}
                          </div>
                          <div className="col-12 mt-4">
                            <label>
                              {inputLabels.identity.nid.nidFront.bn}
                            </label>
                            <input
                              type="file"
                              required={!identityImgFile}
                              id="nid-front"
                              multiple
                              // id='input-file-upload'
                              // identityImgFile.length>0?true:false
                              className=" form-control input-default"
                              onChange={handleNID}
                            />
                          </div>


                        </div>
                      </div>
                    )}
                    {selectedID === "BID" && (
                      <div>
                        <input
                          type="text"
                          {...register("IDnumber", { required: true })}
                          className="form-control"
                          placeholder="Enter Your Birth ID Number"
                          name="IDnumber"
                        // value={bank_contact.number}
                        // onChange={handleChange}
                        />

                        <div className="row">
                          <div className="col-6">
                            {identityImgFile &&
                              identityImgFile.map((i, index) => (
                                <div
                                  className="position-relative d-flex"
                                  style={{ width: "80px" }}
                                >
                                  <div
                                    style={{
                                      height: "70px",
                                      overflow: "hidden",
                                      width: "80px",
                                    }}
                                  >
                                    <img
                                      className="img-fluid"
                                      src={URL.createObjectURL(i)}
                                      alt=""
                                    />
                                  </div>
                                  <div className="position-absolute top-0 end-0">
                                    <ImCross
                                      onClick={() => handleImageDelete(index)}
                                      color="red"
                                    />
                                  </div>
                                </div>
                              ))}
                          </div>
                          <div className="col-12 mt-4">
                            <label>
                              {inputLabels.identity.birthID.birthIdImg.bn}
                            </label>
                            <input
                              type="file"
                              required={!identityImgFile}
                              id="nid-front"
                              multiple
                              // id='input-file-upload'

                              className=" form-control input-default"
                              onChange={handleNID}
                            />
                          </div>

                          {/* <div className='col-6'>
                            <label>{inputLabels.identity.nid.nidBack.bn}</label>
                            <input
                              type='file'
                              required
                              id='Nid-back'
                              // id='input-file-upload'

                              className=' form-control input-default'
                              onChange={handleNIDBack}
                            />
                          </div> */}
                        </div>
                      </div>
                    )}
                    {selectedID === "passport" && (
                      <div>
                        <input
                          type="text"
                          {...register("IDnumber", { required: true })}
                          className="form-control"
                          placeholder="Enter Your Passport Number"
                          name="IDnumber"
                        // value={bank_contact.number}
                        // onChange={handleChange}
                        />
                        <div className="row">
                          <div className="col-6">
                            {identityImgFile &&
                              identityImgFile.map((i, index) => (
                                <div
                                  className="position-relative d-flex"
                                  style={{ width: "80px" }}
                                >
                                  <div
                                    style={{
                                      height: "70px",
                                      overflow: "hidden",
                                      width: "80px",
                                    }}
                                  >
                                    <img
                                      className="img-fluid"
                                      src={URL.createObjectURL(i)}
                                      alt=""
                                    />
                                  </div>
                                  <div className="position-absolute top-0 end-0">
                                    <ImCross
                                      onClick={() => handleImageDelete(index)}
                                      color="red"
                                    />
                                  </div>
                                </div>
                              ))}
                          </div>
                          <div className="col-12 mt-4">
                            <label>
                              {inputLabels.identity.passport.passportImg.bn}
                            </label>
                            <input
                              type="file"
                              required={!identityImgFile}
                              id="nid-front"
                              multiple
                              // id='input-file-upload'

                              className=" form-control input-default"
                              onChange={handleNID}
                            />
                          </div>

                          {/* <div className='col-6'>
                            <label>{inputLabels.identity.nid.nidBack.bn}</label>
                            <input
                              type='file'
                              required
                              id='Nid-back'
                              // id='input-file-upload'

                              className=' form-control input-default'
                              onChange={handleNIDBack}
                            />
                          </div> */}
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="mb-3">
                    <label className="form-label">
                      {inputLabels.birthDate.bn}
                    </label>
                    <input
                      type="text"
                      // onChange={(e) => setName(e.target.value)}
                      className="form-control"
                      // defaultValue={userData.userFullName}
                      name="DateOfBirth"
                      {...register("DateOfBirth", { required: true })}
                    // value={bank_contact.number}
                    // onChange={handleChange}
                    />
                  </div>
                  {errors.DateOfBirth && (
                    <p className="text-danger">{errors.DateOfBirth.message}</p>
                  )}

                  {/* <div>
                    <div className="mb-3">
                      <label className="form-label">
                        <h5>{inputLabels.bankDetails.bn}</h5>
                      </label>
                      <select
                        className="form-select"
                        aria-label="Default select example mb-3"
                        {...register("bankName", { required: false })}
                      >
                        <option> {inputLabels.bankDetails.title.bn}</option>
                        {BankDataSuccess &&
                          BankList?.data?.map((item) => (
                            <option value={item.bankName}>
                              {item.bankName}
                            </option>
                          ))}
                      </select>
                      {errors.bankName && (
                        <span className="text-danger">
                          {" "}
                          {inputLabels.bankDetails.title.bn}{" "}
                        </span>
                      )}
                    </div>
                    <div className="mb-3">
                      <label className="form-label">
                        {inputLabels.accountName.bn}{" "}
                        <span className="text-danger"></span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder={inputLabels.accountName.title.bn}
                        // name='name'
                        {...register("accountName", {
                          required: false, validate: (value) =>
                            /^[a-zA-Z0-9\s]*$/.test(value) || "Special characters are not allowed",
                        })}
                    
                      />
                      {errors.accountName && (
                        <span className="text-danger">
                          Account Name is required
                        </span>
                      )}
                      <div className="form-text">
                     
                      </div>
                    </div>
                    <div className="mb-3">
                      <label className="form-label">
                        {inputLabels.accountNumber.bn}
                        <span className="text-danger"></span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder={inputLabels.accountNumber.bn}
                        // name='number'
                        {...register("accountNumber", {
                          required: false, pattern: {
                            value: /^[0-9]*$/,
                            message: "Only numbers are allowed",
                          },
                        })}
                    
                      />
                      {errors.accountNumber && (
                        <span className="text-danger">{errors.accountNumber.message}</span>

                      )}
                    </div>
                    <div className="mb-3">
                      <label className="form-label">
                        শাখার নাম
                        <span className="text-danger"></span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="শাখার নাম"
                        name="bankBranchName"
                        {...register("bankBranchName", {
                          required: false, validate: (value) =>
                            /^[a-zA-Z0-9\s]*$/.test(value) || "Special characters are not allowed",
                        })}
                    
                      />
                      <div className="form-text">
                        {errors.bankBranchName && (
                          <span className="text-danger">
                            {errors.bankBranchName.message}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="mb-3">
                      <label className="form-label">
                        {inputLabels.bankRoute.bn}{" "}
                        <span className="text-danger"></span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder={inputLabels.bankRoute.title.bn}
                        name="routing"
                        {...register("routingNumber", {
                          required: false, pattern: {
                            value: /^[0-9]*$/,
                            message: "Only numbers are allowed",
                          },
                        })}
                      // onChange={handleChange}
                      />
                      <div className="form-text">
                        {errors.routingNumber && (
                          <span className="text-danger">
                            {errors.routingNumber.message}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="mb-3 border p-3">
                    <label className="form-label">
                      <h5>{inputLabels.mobileBanking.bn} বিবরণ যোগ করুন</h5>
                    </label>

                    {mobileBank.length > 0 && (
                      <table className="table">
                        <thead>
                          <tr>
                            <th>মোবাইল ব্যাংকিং</th>
                            <th>নাম্বার</th>
                            <th>অপশন</th>
                          </tr>
                        </thead>
                        <tbody>
                          {mobileBank &&
                            mobileBank.map((item,index) => (
                              <tr>
                                <td>{item.operatorName}</td>
                                <td>{item.mobileNumber}</td>
                                <td>
                                  <button
                                    className="btn btn-danger"
                                    onClick={() => handleDelete(index)}
                                  >
                                    মুছে ফেলুন 
                                  </button>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    )}
                    <div className="row align-items-center">
                      <div className="col">
                        <label className="form-label">
                          {inputLabels.mobileBanking.bn}
                        </label>
                        <select
                          onChange={(e) => setMobileBankName(e.target.value)}
                          className="form-select"
                          aria-label="Default select example mb-3"
                        // {...register("MblbankName")}
                        >
                          <option>{inputLabels.mobileBanking.title.bn}</option>
                          {MblBankDataSuccess &&
                            MblBankList?.data?.map((item) => (
                              <option value={item.bankName}>
                                {item.bankName}
                              </option>
                            ))}
                        </select>
                        {errors.MblbankName && (
                          <span className="text-danger">Select Bank </span>
                        )}
                      </div>
                      <div className="col">
                        <label className="form-label">
                          {inputLabels.mobileBankingNumber.bn}{" "}
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder={inputLabels.mobileBankingNumber.title.bn}
                          {...register("MobileNumber", {
                            required: "Mobile number is required",
                            validate: (value) => {
                              const isValid = handleValidation(value);
                              return isValid || errorMessage;
                            },
                          })}
                          onChange={(e) => {
                            const value = e.target.value;
                            // const value = e.target.value;
                            if (handleValidation(value)) { // Check if validation passes
                              setNumber(value); // Only set if valid
                            } else {
                              console.error("Validation failed for value:", value);
                            }
                          }}
                        />
                        {errorMessage && <p className="text-danger">{errorMessage}</p>}
                      </div>
                      <div className="col">
                        <Button disabled={isButtonDisabled} onClick={handleMonileBank}>যোগ করুন </Button>
                      </div>
                    </div>
                  </div> */}
                  <div>
                    {/* Bank Details Section */}
                    <div className="mb-3 border p-3 rounded">
                      <h5 className="mb-4">{inputLabels.bankDetails.bn}</h5>
                      <div className="mb-3">
                        <label className="form-label">{inputLabels.bankDetails.title.bn}</label>
                        <select
                          className="form-select"
                          aria-label="Select Bank"
                          {...register("bankName", { required: false })}
                        >
                          <option>{inputLabels.bankDetails.title.bn}</option>
                          {BankDataSuccess &&
                            BankList?.data?.map((item) => (
                              <option key={item.bankName} value={item.bankName}>
                                {item.bankName}
                              </option>
                            ))}
                        </select>
                        {errors.bankName && (
                          <span className="text-danger">{inputLabels.bankDetails.title.bn}</span>
                        )}
                      </div>

                      <div className="row">
                        <div className="col-md-6 mb-3">
                          <label className="form-label">{inputLabels.accountName.bn}</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder={inputLabels.accountName.title.bn}
                            {...register("accountName", {
                              required: false,
                              validate: (value) =>
                                /^[a-zA-Z0-9\s]*$/.test(value) || "Special characters are not allowed",
                            })}
                          />
                          {errors.accountName && (
                            <span className="text-danger">{errors.accountName.message}</span>
                          )}
                        </div>

                        <div className="col-md-6 mb-3">
                          <label className="form-label">{inputLabels.accountNumber.bn}</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder={inputLabels.accountNumber.bn}
                            {...register("accountNumber", {
                              required: false,
                              pattern: {
                                value: /^[0-9]*$/,
                                message: "Only numbers are allowed",
                              },
                            })}
                          />
                          {errors.accountNumber && (
                            <span className="text-danger">{errors.accountNumber.message}</span>
                          )}
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6 mb-3">
                          <label className="form-label">শাখার নাম</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="শাখার নাম"
                            {...register("bankBranchName", {
                              required: false,
                              validate: (value) =>
                                /^[a-zA-Z0-9\s]*$/.test(value) || "Special characters are not allowed",
                            })}
                          />
                          {errors.bankBranchName && (
                            <span className="text-danger">{errors.bankBranchName.message}</span>
                          )}
                        </div>

                        <div className="col-md-6 mb-3">
                          <label className="form-label">{inputLabels.bankRoute.bn}</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder={inputLabels.bankRoute.title.bn}
                            {...register("routingNumber", {
                              required: false,
                              pattern: {
                                value: /^[0-9]*$/,
                                message: "Only numbers are allowed",
                              },
                            })}
                          />
                          {errors.routingNumber && (
                            <span className="text-danger">{errors.routingNumber.message}</span>
                          )}
                        </div>
                      </div>
                    </div>

                    {/* Mobile Banking Section */}
                    <div className="mb-3 border p-3 rounded">
                      <h5 className="mb-4">{inputLabels.mobileBanking.bn} বিবরণ যোগ করুন</h5>

                      {mobileBank.length > 0 && (
                        <table className="table table-bordered">
                          <thead className="table-light">
                            <tr>
                              <th>মোবাইল ব্যাংকিং</th>
                              <th>নাম্বার</th>
                              <th>অপশন</th>
                            </tr>
                          </thead>
                          <tbody>
                            {mobileBank.map((item, index) => (
                              <tr key={index}>
                                <td>{item.operatorName}</td>
                                <td>{item.mobileNumber}</td>
                                <td>
                                  <button
                                    className="btn-sm btn-danger"
                                    onClick={() => handleDelete(index)}
                                  >
                                    মুছে ফেলুন
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      )}

                      <div className="row align-items-center">
                        <div className="col-md-6 mb-3">
                          <label className="form-label">{inputLabels.mobileBanking.bn}</label>
                          <select
                            onChange={(e) => setMobileBankName(e.target.value)}
                            className="form-select"
                          >
                            <option>{inputLabels.mobileBanking.title.bn}</option>
                            {MblBankDataSuccess &&
                              MblBankList?.data?.map((item) => (
                                <option key={item.bankName} value={item.bankName}>
                                  {item.bankName}
                                </option>
                              ))}
                          </select>
                          {errors.MblbankName && (
                            <span className="text-danger">Select Bank</span>
                          )}
                        </div>

                        <div className="col-md-4 mb-3">
                          <label className="form-label">{inputLabels.mobileBankingNumber.bn}</label>
                          <input
                            type="number"
                            className="form-control"
                            placeholder={inputLabels.mobileBankingNumber.title.bn}
                            {...register("MobileNumber", {
                              required:false,
                              // validate: (value) => {
                              //   const isValid = handleValidation(value);
                              //   return isValid || errorMessage;
                              // },
                            })}
                            onChange={(e) => {
                              const value = e.target.value;
                              if (handleValidation(value)) {
                                setNumber(value);
                              }
                            }}
                          />
                          {errorMessage && <p className="text-danger">{errorMessage}</p>}
                        </div>

                        <div className="col-md-2">
                          <button
                            className="text-white p-2 btn-primary w-100"
                            disabled={isButtonDisabled}
                            onClick={handleMonileBank}
                          >
                            যোগ করুন
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div>
                    <Button type="submit">সেইভ </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* <div className='col-xl-4'>
          <div className='card h-auto'>
            <div className='card-body'>
              <div className='widhdrow-side mt-xl-0 mt-4'>
                <div>
                  <span>Amount to be recived</span>
                  <h4>14983320.00</h4>
                </div>
                <div>
                  <span>invoice id</span>
                  <h6>152690258</h6>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
}
export default UpdateBankInfo;
