import React, { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  useGetWishFoodByBuyerQuery,
  useGetWishFoodBySellerQuery,
} from "../../../Redux/API/food";
import axios from "axios";
import { BaseURL } from "../../../Helper/config";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import { getUserDetails } from "../../../Helper/SessionHelper";
import { Button } from "react-bootstrap";
import { LanguageContext } from "../../../context/LanguageContext";
import labels from "../../../translationData/editProfile.json";
import getTranslation from "../../../Helper/getTranslationUtility";
const wishBuyerOrder = labels.userDashboard.wishBuyerOrder;

const WishFoodBuyer = () => {
  const navigate = useNavigate();
  const userData = getUserDetails();

  const { currentLanguage, setCurrentLanguage } = useContext(LanguageContext);


  const { data: WishFoodData } = useGetWishFoodByBuyerQuery(userData?._id);

  // console.log(WishFoodData, "wish-buyer");
  const handleDelete = (data) => {
    axios
      .delete(`${BaseURL}/delete-food/${data}`)
      .then((res) => {
        console.log(res);
      })
      .catch((Err) => {
        console.log(Err);
      });
  };

  const del = (_id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        handleDelete(_id);
        Swal.fire("Deleted!", "Your file has been deleted.", "success");
      }
    });
  };

  const handleCart = async (cart) => {
    if (userData) {
      const token = localStorage.getItem("Token");
      const headers = token 
        ? { "Content-Type": "application/json", token: `${token}` }
        : { "Content-Type": "application/json" };
  
      try {
        await fetch(`${BaseURL}/create-wish-cart-items`, {
          method: "POST",
          headers,
          body: JSON.stringify({
            customerID: userData?._id,
            cartItem: cart,
          }),
        })
          .then((res) => res.json())
          .then((data) => {
            if (data.status === "Success") {
              navigate(`/checkout/${cart._id}`);
              Swal.fire({ title: "Check Out successful!" });
            }
          });
      } catch (e) {
        console.error("Error handling cart:", e);
      }
    }
  };
  


    // Function to change the language
    const changeLanguage = (language) => {
      setCurrentLanguage(language);
    };
  
      //Tab labels:
      const Image = getTranslation(
        "Image.tabTitle",
        currentLanguage,
        wishBuyerOrder
      );
      const foodName = getTranslation(
        "foodName.tabTitle",
        currentLanguage,
        wishBuyerOrder
      );
      const createdBy = getTranslation(
        "createdBy.tabTitle",
        currentLanguage,
        wishBuyerOrder
      );
      const Price = getTranslation(
        "Price.tabTitle",
        currentLanguage,
        wishBuyerOrder
      );
      const Action = getTranslation(
        "Action.tabTitle",
        currentLanguage,
        wishBuyerOrder
      );
      const Confirm = getTranslation(
        "Confirm.tabTitle",
        currentLanguage,
        wishBuyerOrder
      );
      const Reject = getTranslation(
        "Reject.tabTitle",
        currentLanguage,
        wishBuyerOrder
      );

      
  return (
    <>
      <div className='card h-auto'>
        <div className='card-body p-0'>
          <div className='table-responsive'>
            {WishFoodData === undefined ? (
              ""
            ) : (
              <table
                className='table table-list i-table style-1 mb-4 border-0'
                id='guestTable-all3'
              >
                <thead>
                  <tr>
                    <th className='bg-none sorting_asc'>
                      <div className='form-check style-3'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          value=''
                          id='checkAll'
                          //   onClick={() => chackboxFun("all")}
                        />
                      </div>
                    </th>
                    <th>{Image}</th>
                    <th>{foodName}</th>
                    <th>{createdBy}</th>
                    <th>{Price}</th>
                    <th className='bg-none'>{Action}</th>
                  </tr>
                </thead>
                <tbody>
                  {WishFoodData?.data?.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td className='application_sorting_1'>
                          <div className='form-check style-3'>
                            <input
                              className='form-check-input'
                              type='checkbox'
                              value=''
                              //   onClick={() => chackboxFun()}
                            />
                          </div>
                        </td>
                        <td>
                          <div className='media-bx d-flex py-3  align-items-center'>
                            <img
                              className='me-3 rounded-circle'
                              src={item?.foodImage[0]?.orginalImageUrl}
                              alt='images'
                            />
                          </div>
                        </td>
                        <td>
                          <div>
                            <h5 className='mb-0'>{item?.foodName}</h5>
                          </div>
                        </td>
                        <td>
                          <div className='d-flex align-items-center'>
                            <h5 className='mb-0'> {item?.createdDate}</h5>
                          </div>
                          {/* <span>2,97 Km</span> */}
                        </td>

                        <td>
                          <div>
                            {!!item.foodPrice && (
                              <h4 className='text-primary'>
                                {item?.foodPrice}
                              </h4>
                            )}
                            {!item.foodPrice && (
                              <h4 className='text-primary'>not set </h4>
                            )}
                            {/* <h4 className='text-primary'>{item?.foodPrice}</h4> */}
                          </div>
                        </td>
                        <td>
                          <span className='badge badge-xl light badge-success'>
                            {/* <Link to={`/checkout/${item._id}`}> */}
                            <Button onClick={() => handleCart(item)}>
                              {Confirm}
                            </Button>
                        

                            <Button className='ms-2'>{Reject}</Button>

                          </span>
                        </td>

                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default WishFoodBuyer;
