import React, { useState, useEffect, useContext } from "react";
import { LanguageContext } from "../../../context/LanguageContext";
import axios from "axios";
import { BaseURL } from "../../../Helper/config";
import { toast } from "react-toastify";
import { Tab, Button } from "react-bootstrap";
import { useForm, useFieldArray } from "react-hook-form";
import getTranslation from "../../../Helper/getTranslationUtility";
import labels from "../../../translationData/editProfile.json";

const kitchenPhotosVideosTab = labels.userDashboard.editProfile.kitchenPhotosVideosTab;

const KitchenVideos = () => {
    const { currentLanguage } = useContext(LanguageContext);
    const [deletedVideos, setDeletedVideos] = useState([]);
    const [videos, setVideos] = useState([]);
    const { register, handleSubmit, control, reset } = useForm({
        defaultValues: {
            kitchenVideos: [{ video: "" }],
        },
    });
    const { fields, append, remove } = useFieldArray({
        control,
        name: "kitchenVideos",
    });

    const Seller = JSON.parse(localStorage.getItem("UserDetails"));

    const fetchVideos = async () => {
        const token = localStorage.getItem("Token");
        const headers = token ? { token: `${token}` } : {};

        try {
            const response = await axios.get(`${BaseURL}/get-user-details/${Seller._id}`, { headers });
            const sellerData = response.data.data[0]?.sellerData;
            if (sellerData) {
                setVideos(sellerData.videos || []);
                reset({ kitchenVideos: [{ video: "" }] });
            }
        } catch (error) {
            console.error(error);
        }
    };

    const addVideos = async (data) => {
        const token = localStorage.getItem("Token");
        const headers = token ? { token: `${token}` } : {};

        try {
            const newVideoUrls = data.kitchenVideos
                .map((item) => item.video.trim())
                .filter((url) => url !== "");

            // Combine existing videos and new videos, ensuring no duplicates
            const combinedVideos = [...new Set([...videos, ...newVideoUrls])];
           
            const finalVideos = combinedVideos.filter((video) => !deletedVideos.includes(video));

            let fdata = {
                user: {},
                seller: {
                    videos: finalVideos,
                },
            };


            const response = await axios.post(
                `${BaseURL}/update-user-details/${Seller._id}`,
                fdata,
                { headers }
            );
            if (response.data.status === "Success") {
                toast.success("Videos added successfully!");
                fetchVideos();
            } else {
                toast.warning("Failed to add videos.");
            }
        } catch (error) {
            console.error(error);
        }
    };

    const deleteVideo = async (videoUrl) => {
 
            setDeletedVideos((prev) => [...prev, videoUrl]);
            setVideos((prevVideos) => prevVideos.filter((video) => video !== videoUrl));

    
        // const token = localStorage.getItem("Token");
        // const headers = token ? { token: `${token}` } : {};

        // try {
        //     const response = await axios.post(`${BaseURL}/delete-video/${Seller._id}`, { videoUrl }, { headers });
        //     if (response.data.status === "Success") {
        //         toast.success("Video deleted successfully!");
        //         setVideos(videos.filter((video) => video !== videoUrl));
        //     } else {
        //         toast.warning("Failed to delete video.");
        //     }
        // } catch (error) {
        //     console.error(error);
        // }
    };

    const isYouTubeUrl = (url) => {
        return /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/watch\?v=|youtu\.be\/)([a-zA-Z0-9_-]{11})/.test(url);
    };

    const getYouTubeEmbedUrl = (url) => {
        const match = url.match(/(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/watch\?v=|youtu\.be\/)([a-zA-Z0-9_-]{11})/);
        return match ? `https://www.youtube.com/embed/${match[1]}` : null;
    };

    useEffect(() => {
        fetchVideos();
    }, []);

    return (
        <Tab.Pane eventKey="15">
            <div className="setting-right">
                <div className="card">
                    <div className="card-header border-0 pb-0">
                        <h3>{getTranslation("tabDetailsTitle", currentLanguage, kitchenPhotosVideosTab)}</h3>
                    </div>
                    <div className="card-body">
                        {videos.length > 0 ? (
                            <div className="video-list">
                                {videos.map((video, index) => (
                                    <div key={index} className="video-item mb-3">
                                        {isYouTubeUrl(video) ? (
                                            <iframe
                                                width="100%"
                                                height="315"
                                                src={getYouTubeEmbedUrl(video)}
                                                title="YouTube video player"
                                                frameBorder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                allowFullScreen
                                            ></iframe>
                                        ) : (
                                            <video controls width="100%">
                                                <source src={video} type="video/mp4" />
                                                Your browser does not support the video tag.
                                            </video>
                                        )}
                                        <Button variant="danger" className="mt-2" onClick={() => deleteVideo(video)}>
                                            Delete
                                        </Button>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <p>No videos available.</p>
                        )}

                        <form onSubmit={handleSubmit(addVideos)} className="mt-4">
                            <label>Add Videos</label>
                            {fields.map((field, index) => (
                                <div key={field.id} className="d-flex align-items-center mb-2">
                                    <input
                                        type="text"
                                        className="form-control input-default me-2"
                                        placeholder="Enter video URL"
                                        {...register(`kitchenVideos.${index}.video`, { required: true })}
                                    />
                                    <Button variant="danger" onClick={() => remove(index)}>
                                        Remove
                                    </Button>
                                </div>
                            ))}
                            <Button variant="primary" onClick={() => append({ video: "" })} className="mb-3">
                                Add More
                            </Button>
                            <Button type="submit" className="w-100">
                                Save Videos
                            </Button>
                        </form>
                    </div>
                </div>
            </div>
        </Tab.Pane>
    );
};

export default KitchenVideos;
