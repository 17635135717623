// import React, { useState, useEffect, useContext } from "react";

// import { LanguageContext } from "../../../context/LanguageContext";
// import axios from "axios";
// import { BaseURL } from "../../../Helper/config";
// import { Link } from "react-router-dom";
// import { toast } from "react-toastify";
// import { Tab, Nav, Button } from "react-bootstrap";
// import { getUserDetails } from "../../../Helper/SessionHelper";

// import { useForm } from "react-hook-form";
// import bgimage from "../../../images/no-img-avatar.png";
// import {
//   useGetCityByRegionQuery,
//   useGetRegionByCountryQuery,
//   useAllCountryQuery,
//   useGetSellerInfoQuery,
// } from "../../../Redux/API/ApiSlice";
// import PlacesAutocomplete, {
//   geocodeByAddress,
//   getLatLng,
// } from "react-places-autocomplete";
// import { GoogleMap, Marker } from "@react-google-maps/api";
// import { ImageUploader } from "../../../ImageUploader/ImageUploader";
// // import { useGetSellerInfoQuery } from "../Redux/API/ApiSlice";
// import getTranslation from "../../../Helper/getTranslationUtility";
// import labels from "../../../translationData/editProfile.json";
// const basicInfoTab = labels.userDashboard.editProfile.basicInfoTab;

// const BasicInfo = () => {
//   const { currentLanguage, setCurrentLanguage } = useContext(LanguageContext);
//   //Tab labels:
//   const basicInfoTabTitle = getTranslation(
//     "tabTitle",
//     currentLanguage,
//     basicInfoTab
//   );
//   const successMessage = getTranslation(
//     "successMessage",
//     currentLanguage,
//     basicInfoTab
//   );
//   const errorMessage = getTranslation(
//     "errorMessage",
//     currentLanguage,
//     basicInfoTab
//   );

//   const [mainData, setMainData] = useState({});
//   const [selectedCountryAd, setSelectedCountryAd] = useState("");
//   const [selectedRegionAd, setSelectedRegionAd] = useState("");
//   const [userAddress, setUserAddress] = useState(null);
//   const [coordinates, setCoordinates] = useState({
//     lat: null,
//     lng: null,
//   });
//   const { register, handleSubmit, reset, getValues, setValue } = useForm();

//   const Seller = JSON.parse(localStorage.getItem("UserDetails"));

//   const searchOptions = {
//     componentRestrictions: { country: ["BD"] },
//   };
//   const { data, isSuccess } = useAllCountryQuery(undefined);

//   const {
//     data: RegionData,
//     isLoading: RegionLoading,
//     isSuccess: RegionSuccess,
//   } = useGetRegionByCountryQuery(selectedCountryAd);

//   const {
//     data: CityData,
//     isLoading: CityLoading,
//     isSuccess: CitySuccess,
//   } = useGetCityByRegionQuery(selectedRegionAd);

//   const handleCountryChangeAdr = (event) => {
//     const newValue = event.target.value;

//     setSelectedCountryAd(newValue);
//   };

//   const handleRegionChangeAdr = (event) => {
//     const value = event.target.value;
//     setSelectedRegionAd(value);
//   };

//   const handleSelect = async (value) => {
//     const result = await geocodeByAddress(value);
//     const ll = await getLatLng(result[0]);
//     setCoordinates({
//       lat: ll.lat,
//       lng: ll.lng,
//     });
//     setUserAddress(value);
//   };

//   const handleDrag = (event) => {
//     setCoordinates({
//       lat: event.latLng.lat(),
//       lng: event.latLng.lng(),
//     });
//   };

//   const handleMapLoad = (event) => {
//     if (
//       mainData &&
//       mainData.coordinate &&
//       mainData.coordinate.latitude &&
//       mainData.coordinate.longitude
//     ) {
//       setCoordinates({
//         lat: mainData.coordinate.latitude,
//         lng: mainData.coordinate.longitude,
//       });
//     }
//   };

//   const handleBecomeSeller = async () => {
//     if (file) {
//       const datax = await ImageUploader(Seller, "Profile", file);
//       if (datax.status === "Success") {
//         setValue("userProfilePhoto", datax.images);
//         setValue("sellerData.sellerProfilePhoto", datax.images);
//       }
//     }

//     // let fdata = {
//     //     "user": {
//     //         ...getValues(),
//     //         "address": userAddress,
//     //         "coordinate": {
//     //             "longitude": coordinates.lng,
//     //             "latitude": coordinates.lat
//     //         },
//     //         "useProfilePhoto": getValues().userProfilePhoto
//     //     },
//     //     "seller": {
//     //         "sellerProfilePhoto": getValues().userProfilePhoto
//     //     }
//     // };

//     // Assuming you have a function to get the values
//     const values = getValues();

//     // Remove the email and userMobileNo fields from the user object
//     const { email, userMobileNo, ...userWithoutEmailAndMobileNo } = values;

//     const fdata = {
//       user: {
//         ...userWithoutEmailAndMobileNo,
//         address: userAddress,
//         coordinate: {
//           longitude: coordinates.lng,
//           latitude: coordinates.lat,
//         },
//         useProfilePhoto: values.userProfilePhoto,
//       },
//       seller: {
//         sellerProfilePhoto: values.userProfilePhoto,
//       },
//     };

//     axios
//       .post(`${BaseURL}/update-user-details/${Seller._id}`, fdata)
//       .then((res) => {
//         if (res.data.status === "Success") {
//           toast.success(successMessage, {
//             position: "bottom-right",
//             autoClose: 10000,
//             hideProgressBar: false,
//             closeOnClick: true,
//             pauseOnHover: true,
//             draggable: true,
//             progress: undefined,
//             theme: "light",
//           });
//         } else {
//           if (res.data.data.sellerID) {
//             toast.warning(errorMessage);
//           } else toast.warning(errorMessage);
//         }
//       });
//   };

//   //For Image upload in ListBlog
//   const [file, setFile] = React.useState(null);
//   const fileHandler = (e) => {
//     setFile(e.target.files[0]);
//   };

//   const handleInputChange = (event) => {
//     const { name, value } = event.target;

//     setMainData((prev) => {
//       const data = {
//         ...prev,
//         [name]: value,
//       };

//       return data;
//     });
//   };

//   function RemoveImage() {
//     if (
//       mainData &&
//       mainData.userProfilePhoto &&
//       mainData.userProfilePhoto[0].orginalImageUrl
//     ) {
//       setMainData((prev) => {
//         prev.userProfilePhoto[0] = [];
//         return { ...prev };
//       });

//       reset((prev) => {
//         prev.userProfilePhoto[0] = [];
//         return { ...prev };
//       });
//     }

//     return setFile(null);
//   }

//   useEffect(() => {
//     axios
//       .get(`${BaseURL}/get-user-details/${Seller._id}`)
//       .then((res) => {
//         var data = res.data.data[0];
//         var initialData = {
//           userFullName: data.userFullName,
//           email: data.email,
//           userMobileNo: data.userMobileNo,
//           userDateOfBirth: data.userDateOfBirth,
//           countryID: data.countryID,
//           regionID: data.regionID,
//           cityID: data.cityID,
//           address: data.address,
//           coordinate: {
//             latitude: data.coordinate.latitude,
//             longitude: data.coordinate.longitude,
//           },
//           userProfilePhoto: data.userProfilePhoto,
//           sellerData: {
//             sellerProfilePhoto: data.userProfilePhoto,
//           },
//         };

//         data?.countryID && setSelectedCountryAd(data.countryID);
//         data?.regionID && setSelectedRegionAd(data.regionID);
//         data?.address && setUserAddress(data.address);
//         data?.coordinate?.latitude &&
//           data?.coordinate?.longitude &&
//           setCoordinates({
//             lat: parseFloat(data.coordinate.latitude),
//             lng: parseFloat(data.coordinate.longitude),
//           });

//         setMainData(initialData);

//         reset({
//           ...initialData,
//         });
//       })
//       .catch((err) => {
//         console.log(err);
//       });
//   }, [reset]);
//   const userData = getUserDetails();
//   const { data: sellerData, isSuccess: sellerSuccess } = useGetSellerInfoQuery(
//     userData._id
//   );
//   console.log(sellerData, "main data");
//   return (
//     <Tab.Pane eventKey="1">
//       <form onSubmit={handleSubmit(handleBecomeSeller)}>
//         <div className="setting-right">
//           <div className="card">
//             <div className="card-body">
//               <div className="row">
//                 <div className="col-9">
//                   <h3 className="mb-4">{basicInfoTabTitle}</h3>
//                 </div>
//                 <div className="col-3">
//                   <div className="d-flex align-items-center">
//                     {sellerData?.data[0]?.seller[0]?.sellerApproval ===
//                     "Approved" ? (
//                       <>
//                         <i className="fa fa-circle text-success me-1"></i>{" "}
//                         Approved
//                       </>
//                     ) : sellerData?.data[0]?.seller[0]?.sellerApproval ===
//                       "Pending" ? (
//                       <>
//                         <i className="fa fa-circle text-warning me-1"></i>{" "}
//                         Pending
//                       </>
//                     ) : (
//                       <>
//                         {/* <i className='fa fa-circle text-success me-1'></i>{" "}
//                         Success */}
//                       </>
//                     )}
//                   </div>
//                 </div>
//               </div>

//               <p className="fs-18">
//                 {" "}
//                 {getTranslation("profilePhoto", currentLanguage, basicInfoTab)}
//               </p>
//               <div className="setting-img d-flex align-items-center mb-4">
//                 <div className="avatar-upload d-flex align-items-center">
//                   <div className=" change position-relative d-flex">
//                     <div className="avatar-preview">
//                       <img
//                         id="saveImageFile"
//                         src={
//                           file
//                             ? URL.createObjectURL(file)
//                             : (mainData &&
//                                 mainData.userProfilePhoto &&
//                                 mainData.userProfilePhoto[0] &&
//                                 mainData.userProfilePhoto[0].orginalImageUrl) ||
//                               bgimage
//                         }
//                         alt={file ? file.name : null}
//                       />
//                     </div>
//                     <div className="change-btn d-flex align-items-center flex-wrap">
//                       <input
//                         type="file"
//                         onChange={fileHandler}
//                         className="form-control"
//                         id="imageUpload"
//                         accept=".png, .jpg, .jpeg"
//                       />
//                       <label htmlFor="imageUpload" className="dlab-upload">
//                         Choose File
//                       </label>
//                       <Link
//                         to={"#"}
//                         className="btn remove-img ms-2"
//                         onClick={() => RemoveImage()}
//                       >
//                         {" "}
//                         {getTranslation(
//                           "profilePhotoRemove",
//                           currentLanguage,
//                           basicInfoTab
//                         )}{" "}
//                       </Link>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//               <div className="row">
//                 <div className="col-12">
//                   <div className="setting-input">
//                     <label htmlFor="exampleInputtext" className="form-label">
//                       {getTranslation(
//                         "userName",
//                         currentLanguage,
//                         basicInfoTab
//                       )}{" "}
//                     </label>
//                     <input
//                       type="text"
//                       className="form-control input-default "
//                       placeholder={getTranslation(
//                         "userName",
//                         currentLanguage,
//                         basicInfoTab
//                       )}
//                       name="userFullName"
//                       {...register("userFullName", {
//                         required: true,
//                         onChange: handleInputChange,
//                       })}
//                       disabled={
//                         sellerData?.data[0]?.seller[0]?.sellerApproval ===
//                         "Approved"
//                       }
//                     />
//                   </div>
//                   <div className="setting-input">
//                     <label htmlFor="exampleInputtext" className="form-label">
//                       {getTranslation(
//                         "userEmailAddress",
//                         currentLanguage,
//                         basicInfoTab
//                       )}{" "}
//                     </label>
//                     <input
//                       type="email"
//                       className="form-control input-default "
//                       placeholder={getTranslation(
//                         "userEmailAddress",
//                         currentLanguage,
//                         basicInfoTab
//                       )}
//                       name="email"
//                       disabled
//                       {...register("email", {
//                         required: true,
//                         onChange: handleInputChange,
//                       })}
//                     />
//                   </div>
//                 </div>
//                 <div className="col-12">
//                   <div className="setting-input">
//                     <div className="setting-input">
//                       <label htmlFor="exampleInputtext" className="form-label">
//                         {getTranslation(
//                           "userMobileNo",
//                           currentLanguage,
//                           basicInfoTab
//                         )}
//                       </label>
//                       <input
//                         type="text"
//                         className="form-control input-default "
//                         placeholder="Mobile Number"
//                         name="userMobileNo"
//                         disabled
//                         {...register("userMobileNo", {
//                           required: true,
//                           onChange: handleInputChange,
//                         })}
//                       />
//                     </div>
//                   </div>
//                 </div>
//               </div>
//               <div className="row">
//                 <div className="col-12">
//                   <h5>
//                     {getTranslation(
//                       "userAddressHeading",
//                       currentLanguage,
//                       basicInfoTab
//                     )}{" "}
//                   </h5>
//                 </div>
//                 <div className="col-4">
//                   <label>
//                     {getTranslation("country", currentLanguage, basicInfoTab)}{" "}
//                   </label>
//                   <select
//                     name="countryAddress"
//                     className="form-control input-default"
//                     {...register("countryID", {
//                       required: true,
//                       onChange: (e) => {
//                         handleCountryChangeAdr(e);
//                         handleInputChange(e);
//                       },
//                     })}
//                   >
//                     <option>
//                       {getTranslation("country", currentLanguage, basicInfoTab)}{" "}
//                     </option>
//                     {isSuccess &&
//                       data.data?.map((item) => {
//                         if (mainData && mainData.countryID === item._id) {
//                           return (
//                             <option selected value={item._id}>
//                               {item.countryName}
//                             </option>
//                           );
//                         } else {
//                           return (
//                             <option value={item._id}>{item.countryName}</option>
//                           );
//                         }
//                       })}
//                   </select>
//                 </div>
//                 <div className="col-4">
//                   <label>
//                     {getTranslation("region", currentLanguage, basicInfoTab)}{" "}
//                   </label>
//                   <select
//                     className="form-control input-default"
//                     {...register("regionID", {
//                       required: true,
//                       onChange: (e) => {
//                         handleRegionChangeAdr(e);
//                         handleInputChange(e);
//                       },
//                     })}
//                   >
//                     <option>
//                       {getTranslation("region", currentLanguage, basicInfoTab)}{" "}
//                     </option>
//                     {RegionSuccess &&
//                       !RegionLoading &&
//                       RegionData?.data?.map((item) => {
//                         if (mainData && mainData.regionID === item._id) {
//                           return (
//                             <option selected value={item._id}>
//                               {item.regionName}
//                             </option>
//                           );
//                         } else {
//                           return (
//                             <option value={item._id}>{item.regionName}</option>
//                           );
//                         }
//                       })}
//                   </select>
//                 </div>
//                 <div className="col-4">
//                   <label>
//                     {getTranslation("city", currentLanguage, basicInfoTab)}{" "}
//                   </label>
//                   <select
//                     className="form-control input-default"
//                     {...register("cityID", {
//                       required: true,
//                       onChange: (e) => {
//                         handleInputChange(e);
//                       },
//                     })}
//                   >
//                     <option value="">
//                       {getTranslation("city", currentLanguage, basicInfoTab)}{" "}
//                     </option>
//                     {CitySuccess &&
//                       CityData.data?.map((item) => {
//                         if (mainData && mainData.cityID === item._id) {
//                           return (
//                             <option selected value={item._id}>
//                               {item.cityName}
//                             </option>
//                           );
//                         } else {
//                           return (
//                             <option value={item._id}>{item.cityName}</option>
//                           );
//                         }
//                       })}
//                   </select>
//                 </div>
//               </div>

//               {/* Map &  Address Section Start */}
//               <div className="mt-3">
//                 <div className="form-group mb-3 row ">
//                   <div className="col-12">
//                     <label>
//                       {getTranslation(
//                         "pointLocation",
//                         currentLanguage,
//                         basicInfoTab
//                       )}{" "}
//                     </label>
//                     <PlacesAutocomplete
//                       searchOptions={searchOptions}
//                       value={userAddress || ""}
//                       onChange={setUserAddress}
//                       onSelect={handleSelect}
//                     >
//                       {({
//                         getInputProps,
//                         suggestions,
//                         getSuggestionItemProps,
//                         loading,
//                       }) => (
//                         <div style={{ position: "relative" }}>
//                           <input
//                             {...getInputProps({
//                               placeholder: "Search Places ...",
//                               className:
//                                 "form-control input-default location-search-input",
//                             })}
//                           />
//                           <div
//                             className="autocomplete-dropdown-container"
//                             style={{
//                               position: "absolute",
//                               zIndex: 2,
//                               width: "100%",
//                               border: "1px solid #e7e7e7",
//                             }}
//                           >
//                             {loading && <div>Loading...</div>}
//                             {suggestions.map((suggestion) => {
//                               const className = suggestion.active
//                                 ? "suggestion-item--active"
//                                 : "suggestion-item";
//                               // inline style for demonstration purpose
//                               const style = suggestion.active
//                                 ? {
//                                     backgroundColor: "#1E466A",
//                                     color: "white",
//                                     cursor: "pointer",
//                                     padding: "10px",
//                                   }
//                                 : {
//                                     backgroundColor: "#ffffff",
//                                     cursor: "pointer",
//                                     padding: "10px",
//                                   };
//                               return (
//                                 <div
//                                   {...getSuggestionItemProps(suggestion, {
//                                     className,
//                                     style,
//                                   })}
//                                 >
//                                   <span>{suggestion.description}</span>
//                                 </div>
//                               );
//                             })}
//                           </div>
//                         </div>
//                       )}
//                     </PlacesAutocomplete>
//                   </div>
//                 </div>
//                 <GoogleMap
//                   mapContainerStyle={{ height: "200px", width: "100%" }}
//                   //onDragEnd={handleDrag}
//                   center={coordinates} // Centered at the coordinates of Dhaka, Bangladesh
//                   zoom={20}
//                   onLoad={handleMapLoad}
//                 >
//                   <Marker
//                     position={coordinates}
//                     draggable
//                     onDragEnd={handleDrag}
//                   />
//                 </GoogleMap>
//               </div>
//               <div className="col-12 mt-3">
//                 <Button type="submit" className="w-100">
//                   {getTranslation("saveButton", currentLanguage, basicInfoTab)}{" "}
//                 </Button>
//               </div>
//             </div>
//           </div>
//         </div>
//       </form>
//     </Tab.Pane>
//   );
// };

// export default BasicInfo;
import React, { useState, useEffect, useContext } from "react";

import { LanguageContext } from "../../../context/LanguageContext";
import axios from "axios";
import { BaseURL } from "../../../Helper/config";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Tab, Nav, Button } from "react-bootstrap";
import { getUserDetails } from "../../../Helper/SessionHelper";

import { useForm } from "react-hook-form";
import bgimage from "../../../images/no-img-avatar.png";
import {
  useGetCityByRegionQuery,
  useGetRegionByCountryQuery,
  useAllCountryQuery,
  useGetSellerInfoQuery,
  useGetThanaByRegionQuery,
} from "../../../Redux/API/ApiSlice";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { GoogleMap, Marker } from "@react-google-maps/api";
import { ImageUploader } from "../../../ImageUploader/ImageUploader";
// import { useGetSellerInfoQuery } from "../Redux/API/ApiSlice";
import getTranslation from "../../../Helper/getTranslationUtility";
import labels from "../../../translationData/editProfile.json";
const basicInfoTab = labels.userDashboard.editProfile.basicInfoTab;

const BasicInfo = () => {
  const { currentLanguage, setCurrentLanguage } = useContext(LanguageContext);
  //Tab labels:
  const basicInfoTabTitle = getTranslation(
    "tabTitle",
    currentLanguage,
    basicInfoTab
  );
  const successMessage = getTranslation(
    "successMessage",
    currentLanguage,
    basicInfoTab
  );
  const errorMessage = getTranslation(
    "errorMessage",
    currentLanguage,
    basicInfoTab
  );

  const [mainData, setMainData] = useState({});
  const [selectedCountryAd, setSelectedCountryAd] = useState("");
  const [selectedRegionAd, setSelectedRegionAd] = useState("");
  const [userAddress, setUserAddress] = useState(null);
  const [coordinates, setCoordinates] = useState({
    lat: null,
    lng: null,
  });
  const { register, handleSubmit, reset, getValues, setValue } = useForm();

  const Seller = JSON.parse(localStorage.getItem("UserDetails"));

  const searchOptions = {
    componentRestrictions: { country: ["BD"] },
  };
  const { data, isSuccess } = useAllCountryQuery(undefined);

  const {
    data: RegionData,
    isLoading: RegionLoading,
    isSuccess: RegionSuccess,
  } = useGetRegionByCountryQuery(selectedCountryAd);

  // const {
  //   data: CityData,
  //   isLoading: CityLoading,
  //   isSuccess: CitySuccess,
  // } = useGetCityByRegionQuery(selectedRegionAd);
  const {
    data: CityData,
    isLoading: CityLoading,
    isSuccess: CitySuccess,
  } = useGetThanaByRegionQuery(selectedRegionAd);

  const handleCountryChangeAdr = (event) => {
    const newValue = event.target.value;

    setSelectedCountryAd(newValue);
  };

  const handleRegionChangeAdr = (event) => {
    const value = event.target.value;
    setSelectedRegionAd(value);
  };

  const handleSelect = async (value) => {
    const result = await geocodeByAddress(value);
    const ll = await getLatLng(result[0]);
    setCoordinates({
      lat: ll.lat,
      lng: ll.lng,
    });
    setUserAddress(value);
  };

  const handleDrag = (event) => {
    setCoordinates({
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    });
  };

  const handleMapLoad = (event) => {
    if (
      mainData &&
      mainData.coordinate &&
      mainData.coordinate.latitude &&
      mainData.coordinate.longitude
    ) {
      setCoordinates({
        lat: mainData.coordinate.latitude,
        lng: mainData.coordinate.longitude,
      });
    }
  };

  const handleBecomeSeller = async () => {
    // Retrieve token from localStorage
    const token = localStorage.getItem("Token");
    const headers = token ? { token: `${token}` } : {}; // Set headers with token
  
    if (file) {
      const datax = await ImageUploader(Seller, "Profile", file);
      if (datax.status === "Success") {
        setValue("userProfilePhoto", datax.images);
        setValue("sellerData.sellerProfilePhoto", datax.images);
      }
    }
  
    // Assuming you have a function to get the values
    const values = getValues();
  
    // Convert cityNumericId to a number
    const regionNumericId = Number(values.regionNumericId);
    const cityNumericId = Number(values.cityNumericId);
  
    // Remove the email and userMobileNo fields from the user object
    const { email, userMobileNo, ...userWithoutEmailAndMobileNo } = values;
    const finalData = {
      ...userWithoutEmailAndMobileNo,
      regionNumericId: regionNumericId,
      cityNumericId: cityNumericId,
    };
  
    const fdata = {
      user: {
        ...finalData,
        address: userAddress,
        coordinate: {
          longitude: coordinates.lng,
          latitude: coordinates.lat,
        },
        useProfilePhoto: values.userProfilePhoto,
      },
      seller: {
        // sellerProfilePhoto: values.userProfilePhoto,
      },
    };
  
    // Send the POST request with token in headers
    axios
      .post(`${BaseURL}/update-user-details/${Seller._id}`, fdata, { headers })
      .then((res) => {
        if (res.data.status === "Success") {
          toast.success(successMessage, {
            position: "bottom-right",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        } else {
          if (res.data.data.sellerID) {
            toast.warning(errorMessage);
          } else {
            toast.warning(errorMessage);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  

  //For Image upload in ListBlog
  const [file, setFile] = React.useState(null);
  const fileHandler = (e) => {
    setFile(e.target.files[0]);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setMainData((prev) => {
      const data = {
        ...prev,
        [name]: value,
      };

      return data;
    });
  };

  function RemoveImage() {
    if (
      mainData &&
      mainData.userProfilePhoto &&
      mainData.userProfilePhoto[0].orginalImageUrl
    ) {
      setMainData((prev) => {
        prev.userProfilePhoto[0] = [];
        return { ...prev };
      });

      reset((prev) => {
        prev.userProfilePhoto[0] = [];
        return { ...prev };
      });
    }

    return setFile(null);
  }



  useEffect(() => {
    const token = localStorage.getItem("Token"); // Retrieve token from localStorage
    const headers = token ? { token: `${token}` } : {}; // Set headers with token
  
    axios
      .get(`${BaseURL}/get-user-details/${Seller._id}`, { headers }) // Pass headers with token
      .then((res) => {
        var data = res.data.data[0];
        var initialData = {
          userFullName: data.userFullName,
          email: data.email,
          userMobileNo: data.userMobileNo,
          userDateOfBirth: data.userDateOfBirth,
          countryID: data.countryID,
          regionID: data.regionID,
          regionNumericId: data.regionNumericId,
          cityID: data.cityID,
          cityNumericId: data.cityNumericId,
          address: data.address,
          coordinate: {
            latitude: data.coordinate.latitude,
            longitude: data.coordinate.longitude,
          },
          userProfilePhoto: data.userProfilePhoto,
          sellerData: {
            sellerProfilePhoto: data.userProfilePhoto,
          },
        };
  
        data?.countryID && setSelectedCountryAd(data.countryID);
        // data?.regionID && setSelectedRegionAd(data.regionID);
        data?.regionNumericId && setSelectedRegionAd(data.regionNumericId);
        data?.address && setUserAddress(data.address);
        data?.coordinate?.latitude &&
          data?.coordinate?.longitude &&
          setCoordinates({
            lat: parseFloat(data.coordinate.latitude),
            lng: parseFloat(data.coordinate.longitude),
          });
  
        setMainData(initialData);
  
        reset({
          ...initialData,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }, [reset]);
  

  const userData = getUserDetails();
  const { data: sellerData, isSuccess: sellerSuccess } = useGetSellerInfoQuery(
    userData._id
  );
  console.log(sellerData, "main data");
  return (
    <Tab.Pane eventKey="1">
      <form onSubmit={handleSubmit(handleBecomeSeller)}>
        <div className="setting-right">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-9">
                  <h3 className="mb-4">{basicInfoTabTitle}</h3>
                </div>
                <div className="col-3">
                  <div className="d-flex align-items-center">
                    {sellerData?.data[0]?.seller[0]?.sellerApproval ===
                    "Approved" ? (
                      <>
                        <i className="fa fa-circle text-success me-1"></i>{" "}
                        Approved
                      </>
                    ) : sellerData?.data[0]?.seller[0]?.sellerApproval ===
                      "Pending" ? (
                      <>
                        <i className="fa fa-circle text-warning me-1"></i>{" "}
                        Pending
                      </>
                    ) : (
                      <>
                        {/* <i className='fa fa-circle text-success me-1'></i>{" "}
                        Success */}
                      </>
                    )}
                  </div>
                </div>
              </div>

              <p className="fs-18">
                {" "}
                {getTranslation("profilePhoto", currentLanguage, basicInfoTab)}
              </p>
              <div className="setting-img d-flex align-items-center mb-4">
                <div className="avatar-upload d-flex align-items-center">
                  <div className=" change position-relative d-flex">
                    <div className="avatar-preview">
                      <img
                        id="saveImageFile"
                        src={
                          file
                            ? URL.createObjectURL(file)
                            : (mainData &&
                                mainData.userProfilePhoto &&
                                mainData.userProfilePhoto[0] &&
                                mainData.userProfilePhoto[0].orginalImageUrl) ||
                              bgimage
                        }
                        alt={file ? file.name : null}
                      />
                    </div>
                    <div className="change-btn d-flex align-items-center flex-wrap">
                      <input
                        type="file"
                        onChange={fileHandler}
                        className="form-control"
                        id="imageUpload"
                        accept=".png, .jpg, .jpeg"
                      />
                      <label htmlFor="imageUpload" className="dlab-upload">
                        Choose File
                      </label>
                      <Link
                        to={"#"}
                        className="btn remove-img ms-2"
                        onClick={() => RemoveImage()}
                      >
                        {" "}
                        {getTranslation(
                          "profilePhotoRemove",
                          currentLanguage,
                          basicInfoTab
                        )}{" "}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="setting-input">
                    <label htmlFor="exampleInputtext" className="form-label">
                      {getTranslation(
                        "userName",
                        currentLanguage,
                        basicInfoTab
                      )}{" "}
                    </label>
                    <input
                      type="text"
                      className="form-control input-default "
                      placeholder={getTranslation(
                        "userName",
                        currentLanguage,
                        basicInfoTab
                      )}
                      name="userFullName"
                      {...register("userFullName", {
                        required: true,
                        onChange: handleInputChange,
                      })}
                      disabled={
                        sellerData?.data[0]?.seller[0]?.sellerApproval ===
                        "Approved"
                      }
                    />
                  </div>
                  <div className="setting-input">
                    <label htmlFor="exampleInputtext" className="form-label">
                      {getTranslation(
                        "userEmailAddress",
                        currentLanguage,
                        basicInfoTab
                      )}{" "}
                    </label>
                    <input
                      type="email"
                      className="form-control input-default "
                      placeholder={getTranslation(
                        "userEmailAddress",
                        currentLanguage,
                        basicInfoTab
                      )}
                      name="email"
                      disabled
                      {...register("email", {
                        required: true,
                        onChange: handleInputChange,
                      })}
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="setting-input">
                    <div className="setting-input">
                      <label htmlFor="exampleInputtext" className="form-label">
                        {getTranslation(
                          "userMobileNo",
                          currentLanguage,
                          basicInfoTab
                        )}
                      </label>
                      <input
                        type="text"
                        className="form-control input-default "
                        placeholder="Mobile Number"
                        name="userMobileNo"
                        disabled
                        {...register("userMobileNo", {
                          required: true,
                          onChange: handleInputChange,
                        })}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <h5>
                    {getTranslation(
                      "userAddressHeading",
                      currentLanguage,
                      basicInfoTab
                    )}{" "}
                  </h5>
                </div>
                <div className="col-4">
                  <label>
                    {getTranslation("country", currentLanguage, basicInfoTab)}{" "}
                  </label>
                  <select
                    name="countryAddress"
                    className="form-control input-default"
                    {...register("countryID", {
                      required: true,
                      onChange: (e) => {
                        handleCountryChangeAdr(e);
                        handleInputChange(e);
                      },
                    })}
                  >
                    <option>
                      {getTranslation("country", currentLanguage, basicInfoTab)}{" "}
                    </option>
                    {isSuccess &&
                      data.data?.map((item) => {
                        if (mainData && mainData.countryID === item._id) {
                          return (
                            <option selected value={item._id}>
                              {item.countryName}
                            </option>
                          );
                        } else {
                          return (
                            <option value={item._id}>{item.countryName}</option>
                          );
                        }
                      })}
                  </select>
                </div>
                <div className="col-4">
                  <label>
                    {getTranslation("region", currentLanguage, basicInfoTab)}{" "}
                  </label>
                  <select
                    className="form-control input-default"
                    {...register("regionNumericId", {
                      required: true,
                      onChange: (e) => {
                        handleRegionChangeAdr(e);
                        handleInputChange(e);
                      },
                    })}
                  >
                    <option>
                      {getTranslation("region", currentLanguage, basicInfoTab)}{" "}
                    </option>
                    {RegionSuccess &&
                      !RegionLoading &&
                      RegionData?.data?.map((item) => {
                        if (mainData && mainData.regionNumericId === item.id) {
                          return (
                            <option selected value={item.id}>
                              {item.regionName}
                            </option>
                          );
                        } else {
                          return (
                            <option value={item.id}>{item.regionName}</option>
                          );
                        }
                      })}
                  </select>
                </div>
                <div className="col-4">
                  <label>
                    {getTranslation("city", currentLanguage, basicInfoTab)}{" "}
                  </label>
                  <select
                    className="form-control input-default"
                    {...register("cityNumericId", {
                      required: true,
                      onChange: (e) => {
                        handleInputChange(e);
                      },
                    })}
                  >
                    <option value="">
                      {getTranslation("city", currentLanguage, basicInfoTab)}{" "}
                    </option>
                    {CitySuccess &&
                      CityData.data?.map((item) => {
                        if (mainData && mainData.cityNumericId === item.id) {
                          return (
                            <option selected value={item.id}>
                              {item.cityName}
                            </option>
                          );
                        } else {
                          return (
                            <option value={item.id}>{item.cityName}</option>
                          );
                        }
                      })}
                  </select>
                </div>
              </div>

              {/* Map &  Address Section Start */}
              <div className="mt-3">
                <div className="form-group mb-3 row ">
                  <div className="col-12">
                    <label>
                      {getTranslation(
                        "pointLocation",
                        currentLanguage,
                        basicInfoTab
                      )}{" "}
                    </label>
                    <PlacesAutocomplete
                      searchOptions={searchOptions}
                      value={userAddress || ""}
                      onChange={setUserAddress}
                      onSelect={handleSelect}
                    >
                      {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading,
                      }) => (
                        <div style={{ position: "relative" }}>
                          <input
                            {...getInputProps({
                              placeholder: "Search Places ...",
                              className:
                                "form-control input-default location-search-input",
                            })}
                          />
                          <div
                            className="autocomplete-dropdown-container"
                            style={{
                              position: "absolute",
                              zIndex: 2,
                              width: "100%",
                              border: "1px solid #e7e7e7",
                            }}
                          >
                            {loading && <div>Loading...</div>}
                            {suggestions.map((suggestion) => {
                              const className = suggestion.active
                                ? "suggestion-item--active"
                                : "suggestion-item";
                              // inline style for demonstration purpose
                              const style = suggestion.active
                                ? {
                                    backgroundColor: "#1E466A",
                                    color: "white",
                                    cursor: "pointer",
                                    padding: "10px",
                                  }
                                : {
                                    backgroundColor: "#ffffff",
                                    cursor: "pointer",
                                    padding: "10px",
                                  };
                              return (
                                <div
                                  {...getSuggestionItemProps(suggestion, {
                                    className,
                                    style,
                                  })}
                                >
                                  <span>{suggestion.description}</span>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </PlacesAutocomplete>
                  </div>
                </div>
                {console.log("map coordinates", coordinates)}
                <GoogleMap
                  mapContainerStyle={{ height: "200px", width: "100%" }}
                  //onDragEnd={handleDrag}
                  center={coordinates} // Centered at the coordinates of Dhaka, Bangladesh
                  zoom={20}
                  onLoad={handleMapLoad}
                >
                  <Marker
                    position={coordinates}
                    draggable
                    onDragEnd={handleDrag}
                  />
                </GoogleMap>
              </div>
              <div className="col-12 mt-3">
                <Button type="submit" className="w-100">
                  {getTranslation("saveButton", currentLanguage, basicInfoTab)}{" "}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </Tab.Pane>
  );
};

export default BasicInfo;
